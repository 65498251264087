/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { RoleFunction } from '@eagle/common';
import { Dashboard } from '@eagle/core-data-types';
import { FeatureIcons, FindItemsDeferredResult, getListResultDescription, ListPage, ListPageQuery, MiddleSpinner, Pagination, PortalFeatureIcons, ResultsDetailCard, SearchResultsTableView, Undefinable, useAuthenticated, useBoolFlag, useCustomRoutes, useDynamicModule, useHasAuthorization } from '@eagle/react-common';
import { Button, Card, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Axios from 'axios';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHref, useNavigate } from 'react-router-dom';
import { DASHBOARD_DETAIL_FLAG } from '../dashboard-detail/dashboard-detail';

export const ReportsList: FC = () => {
  const { axios, restClient } = useAuthenticated();
  const { t } = useTranslation(['common', 'track']);
  const { reports } = useCustomRoutes();
  const navigate = useNavigate();
  const href = useHref(`/${reports}`);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const { hasAuthorization } = useHasAuthorization();
  const SYSTEM_ADMINISTRATOR_ROLE = [RoleFunction.SYSTEM_ADMINISTRATOR] as const;
  const hasManagePermissions = hasAuthorization(SYSTEM_ADMINISTRATOR_ROLE) && useBoolFlag(DASHBOARD_DETAIL_FLAG);

  const navigateToReports = (item: Dashboard): void => {
    navigate(`/${reports}/${encodeURIComponent(item._id)}`);
  };

  const findReports = useCallback(({ pagination, search }: ListPageQuery): FindItemsDeferredResult<Dashboard> => {
    const cancelToken = Axios.CancelToken.source();

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.my.dashboards.getAllV2({
        ...pagination,
        ...(search ? { search } : {}),
        sort: !search ? JSON.stringify({ display: 'asc' }) : undefined,
        filter: { type: 'report' },
      }, { cancelToken: cancelToken.token }).then((response) => {
        const matchCount = response.count ?? 0;
        const resultDescription = getListResultDescription({ count: matchCount, entityKey: 'common:terms.report', search, t });

        return {
          result: {
            results: response.items,
            itemCount: matchCount,
          },
          resultDescription,
        };
      }),
    };
  }, [axios]);

  const renderDesktopContent = (isLoading: boolean, items: Dashboard[]): JSX.Element => <>
    <TableBody component="div" sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
      {
        items.map((item, i) =>
          <TableRow
            key={i}
            component={Link}
            hover
            to={`/${reports}/${item._id}`}
            sx={{ cursor: 'pointer', height: 60, textDecoration: 'none' }}
          >
            <TableCell component="div">
              <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>{item.display}</Typography>
                {hasManagePermissions
                  && <Button
                    onClick={(e) => {
                      navigate(`/${reports}/manage/${item._id}`);
                      e.preventDefault();
                    }}
                    variant="text"
                  >
                    {t('track:page.dashboards.manage.action')}
                  </Button>
                }
              </Stack>
            </TableCell>
          </TableRow>
        )}
    </TableBody>
  </>;

  const renderMobileContent = (_displayOnCard: boolean, isLoading: boolean, items: Dashboard[]): JSX.Element =>
    <Stack spacing={1} sx={{ width: 1 }}>
      {items.map((item, i) => {
        return (
          <Card key={i} sx={{ filter: isLoading ? 'blur(1px)' : '', overflow: 'visible', width: 1 }}>
            <ResultsDetailCard
              content={<Typography>{item.display}</Typography>}
              href={`${href}/${item._id}`}
              sx={{ py: 1, px: 2 }}
            />
          </Card>
        );
      })}
    </Stack>;

  const renderTableContent = (
    isLoading: boolean,
    items: Undefinable<Dashboard[]>,
    matchCount: number,
    pagination: Pagination,
    setPagination: (value: Pagination) => void,
    text: Undefinable<string>,
    error?: Error,
  ): JSX.Element => (
    <SearchResultsTableView<Dashboard>
      data-testid='reports-list-results'
      isLoading={isLoading}
      items={items}
      matchCount={matchCount}
      noResultsInstructions={t('track:page.reports-list.no-reports.hint')}
      pagination={pagination}
      renderDesktopContent={renderDesktopContent}
      renderMobileContent={renderMobileContent}
      setPagination={setPagination}
      text={text}
      error={error}
    />
  );

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <ListPage<Dashboard>
      data-testid='reports-list'
      actions={[]}
      filterFields={[]}
      icon={module?.ReportsIcon && <module.ReportsIcon />}
      navigateToEntity={navigateToReports}
      onQueryChanged={findReports}
      renderTableContent={renderTableContent}
      searchRemoved
      showFilterButton={false}
      storageKey="reports"
      title={t('track:page.reports.title')}
    />
  );
};
